<template>
  <div>
    <div v-show="publicResource">
      <div class="apply_box_top">
        <div class="apply_box_top_left">
          <div class="apply_box_total">
            <div class="apply_box_total_icon">
              <i class="iconfont icon-hezuoshixin"></i>
            </div>
            <div class="apply_box_total_text apply_box_total_text_special">
              <span>融资总数(笔)</span>
              <span class="apply_box_total_text50">{{supplierNum}}</span>
              <span>/</span>
              <span>{{alreadyInviteNum}}</span>
            </div>
          </div>
        </div>
        <div class="apply_box_top_right">
          <div ref="echarts1" class="echarts_box"></div>
        </div>
      </div>
      <div class="table_common">
        <div class="search_flex_box">
          <div class="search_condition search_condition_right">
            <el-form ref="financingForm" :model="financingForms">
              <el-form-item label="融资编号:" class="financing_number financing_border" prop="financingNum">
                <el-input v-model="financingForms.financingNum" @input="searchInput" maxlength="20">
                  <template slot="prepend">FN</template>
                </el-input>
              </el-form-item>
              <el-form-item label="融资企业名称:" prop="financingName">
                <el-input v-model="financingForms.financingName"></el-input>
              </el-form-item>
              <el-form-item label="融资企业类型:" prop="financingEnterpriseType">
                <el-select v-model="financingForms.financingEnterpriseType" placeholder="" class="select_class">
                  <el-option v-for="item in  companyState" :key="item.index" :label="item.value" :value="item.codeState"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="融资企业评级:" prop="financingEnterpriseGrade">
                <el-select v-model="financingForms.financingEnterpriseGrade" placeholder="" class="select_class">
                  <el-option v-for="itemGrade in gradeLists" :key="itemGrade.index" :label="itemGrade.value" :value="itemGrade.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="申请日期:" class="financing_number" prop="applyDate">
                <el-date-picker v-model="financingForms.applyDate" prefix-icon="iconfont icon-riqi" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  value-format="yyyy-MM-dd" :picker-options="applyDateChoose" :editable="false">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="您的偏好:" prop="preference" class="financing_number">
                <el-select v-model="financingForms.preference" placeholder="" class="select_class">
                  <el-option v-for="item in yuoPreference" :key="item.index" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="search_boxs flex_center_between_box">
            <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
              <div class="search_button_box" @click="search_buttons()">
                <i class="iconfont icon-10sousuo"></i>
              </div>
            </el-tooltip>
            <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
              <div class="reset_button_box" @click="resetButton()">
                <i class="iconfont icon-shuaxin"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="table_box">
          <div class="table_box_top">
            <div class="search_button button data_export">数据导出</div>
          </div>
          <div class="table_box_bottom">
            <el-table ref="treeTable" :data="tableData" stripe border style="width: 100%" :row-key="id">
              <el-table-column type="expand" align="center" width="1" class-name="border_change">
                <template slot-scope="scope">
                  <div class="tree_table_box">
                    <el-table ref="treeTableData" :data="treeTableData" stripe border :row-key="scope.row.id" style="width: 100%">
                      <el-table-column label="发票号" prop="invoiceNo" align="center" show-overflow-tooltip :resizable="false" />
                      <el-table-column label="采购商名称" prop="purchaseName" align="left" header-align="center" class-name="financing_cursor" show-overflow-tooltip :resizable="false" :width="nameWidth">
                        <template slot-scope="scope">
                          <div class="link" @click="detailIndex1(scope.row)">{{scope.row.purchaseName}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="采购商评级" prop="supplyGrade" align="center" show-overflow-tooltip :resizable="false" :width="width120" />
                      <el-table-column label="发票日期" prop="invoiceDate" align="center" sortable show-overflow-tooltip :resizable="false" :width="dateWidth" />
                      <el-table-column label="发票金额(元)" prop="invoiceAmount" align="right" header-align="center" sortable show-overflow-tooltip :width="width150s" :resizable="false">
                        <template slot-scope="scope">
                          {{scope.row.invoiceAmount | NumFormat}}
                        </template>
                      </el-table-column>
                      <el-table-column label="发票确认状态" prop="isConfirm" align="center" show-overflow-tooltip :resizable="false" :width="width120">
                        <template slot-scope="scope">
                          <div v-if="scope.row.isConfirm == 0">未认可</div>
                          <div v-else>认可</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="发票评级" prop="invoiceGrade" align="center" show-overflow-tooltip :resizable="false">
                        <template slot-scope="scope">
                          <div class="link" @click="clickInvoiceGrade(scope.row.invoiceGrade)">{{scope.row.invoiceGrade}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="发票账期(天)" prop="accPeriod" align="center" sortable show-overflow-tooltip :resizable="false" :width="width150s" />
                      <el-table-column label="发票付款状态" prop="invoiceState" align="center" show-overflow-tooltip :resizable="false" :width="width150s">
                        <template slot-scope="scope">
                          <div v-if="scope.row.invoiceState == 1">已付款</div>
                          <div v-else>未付款</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="操 作" prop="operation" align="center" :resizable="false" fixed="right">
                        <template slot-scope="scope">
                          <div class="table_oper flex_center">
                            <el-tooltip class="item_color" effect="light" content="详情" placement="top">
                              <i class="iconfont icon-shuxingliebiaoxiangqing" @click="detailsInvoice(scope.row,scope.$index)"></i>
                            </el-tooltip>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <Page :totalFont=true :total=page1.total :currentPage='page1.pageNo' @onPageChange="onPageChangeList"></Page>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="融资编号" prop="financingNum" show-overflow-tooltip align="center" :resizable="false" class-name="financing_cursor" :width="width190">
                <template slot-scope="scope">
                  <span class="expans_icon" :class="{'expandIconExpanded':scope.row.isExpand}" @click="onClickExpansion(scope.row)"><i class="iconfont icon-youjiantou"></i>
                    {{scope.row.financingNum}}</span>
                </template>
              </el-table-column>
              <el-table-column label="融资企业名称" prop="financingName" align="left" header-align="center" show-overflow-tooltip :resizable="false" class-name="financing_cursor" :width="nameWidth">
                <template slot-scope="scope">
                  <!-- <a href="#" class="link" @click="detailIndex(scope.row)">{{scope.row.financingName}}</a>   -->
                  <div class="link" @click="detailIndex(scope.row)">{{scope.row.financingName}}</div>
                </template>
              </el-table-column>
              <el-table-column label="申请日期" prop="createDate" align="center" sortable show-overflow-tooltip :resizable="false" :width="dateWidth" />
              <el-table-column label="融资企业类型" prop="financingEnterpriseType" align="center" show-overflow-tooltip :resizable="false" :width="width140" />
              <el-table-column label="融资企业评级" prop="financingEnterpriseGrade" align="center" show-overflow-tooltip :resizable="false" :width="width140" />
              <el-table-column label="商业评级" prop="commercialCreditRating" align="center" show-overflow-tooltip :resizable="false" :width="width100" />
              <el-table-column label="融资金额(元)" prop="supplierFinancingAmount" align="right" header-align="center" sortable show-overflow-tooltip :resizable="false" :width="width145">
                <template slot-scope="scope">
                  {{scope.row.supplierFinancingAmount | NumFormat}}
                </template>
              </el-table-column>
              <el-table-column label="发票数量" prop="financingInvoiceNum" align="center" sortable show-overflow-tooltip :resizable="false" :width="width115" />
              <el-table-column label="发票确认数量" prop="financingInvoiceNum" align="center" sortable show-overflow-tooltip :resizable="false" :width="width150s" />
              <el-table-column label="操 作" prop="operation" align="center" :resizable="false" fixed="right">
                <template slot-scope="scope">
                  <div class="table_oper flex_center">
                    <el-tooltip class="item_color_money" effect="light" content="融资邀约" placement="top">
                      <i class="iconfont icon-hezuo_gongshi" @click="financingInvite(scope.row,scope.$index)"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Page :totalFont=true :total=page.total :currentPage='page.pageNo' @onPageChange="onPageChange"></Page>
        </div>
      </div>
    </div>
    <!-- 发票评级弹窗 -->
    <el-dialog title="发票评级" :visible.sync="invoiceGradeDialog" center class="dialog_1268" :close-on-click-modal="false">
      <img :src="invoiceAImg" v-if="invoiceImg == 1">
      <img :src="invoiceBImg" v-else-if="invoiceImg == 2">
      <img :src="invoiceCImg" v-else-if="invoiceImg == 3">
      <img :src="invoiceDImg" v-else-if="invoiceImg == 4">
      <img :src="invoiceNaImg" v-else>
    </el-dialog>
    <!-- 融资邀约弹框 -->
    <el-dialog title="我的邀约" :visible.sync="financingInviteDialog" class="change_padding" center :close-on-click-modal="false">
      <div class="base_message_box public_box">
        <div class="public_title">我的基本信息</div>
        <el-form :model="baseForm" :rules="rulesForm" ref="baseForm1">
          <el-form-item label="公司名称:" prop="companyName">
            <el-input v-model="baseForm.companyName" disabled></el-input>
          </el-form-item>
          <el-form-item label="客户经理:" prop="accountManager">
            <el-input v-model="baseForm.accountManager"></el-input>
          </el-form-item>
          <el-form-item label="联系方式:" prop="contactInfo">
            <el-input v-model="baseForm.contactInfo" maxlength="11"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="lending_conditions_box public_box">
        <div class="public_title">放贷条件</div>
        <el-form :model="baseForm" :rules="rulesForm1" ref="baseForm2">
          <el-form-item label="放款天数:" prop="managementArrivalDate">
            <el-input v-model="baseForm.supplierArrivalDate" disabled></el-input>
            <i class="iconfont icon-youjiantou"></i>
            <el-input class="change_picker" v-model.number="baseForm.managementArrivalDate" @change="exceptArrivalChange(),changeDay()" maxlength="3" @input="codeInput"></el-input>
          </el-form-item>
          <el-form-item label="还款日期:" prop="managementRepaymentDate" :class="exceptRepaymentClass ? 'exceptArrivalClass' : ''">
            <el-input v-model="baseForm.supplierRepaymentDate" disabled></el-input>
            <i class="iconfont icon-youjiantou"></i>
            <el-date-picker type="date" prefix-icon="iconfont icon-riqi" placeholder="" v-model="baseForm.managementRepaymentDate" class="change_picker" value-format="yyyy-MM-dd"
              :picker-options="pickerOptions2" @change="exceptArrivalChange()" :editable="false"></el-date-picker>
          </el-form-item>
          <el-form-item label="融资金额:" prop="managementFinancingAmount">
            <el-input v-model="baseForm.supplierFinancingAmount" disabled></el-input>
            <i class="iconfont icon-youjiantou"></i>
            <el-input v-model.number="baseForm.managementFinancingAmount"  class="change_picker" @focus="inputMoneyFocusfin(baseForm.managementFinancingAmount)"
              @blur="inputMoneyBlurfin(baseForm.managementFinancingAmount)" @change="exceptArrivalChange()" maxlength="8" @input="codeInput1"></el-input>
            </el-form-item>
          <el-form-item label="融资利率:" prop="managementRate">
            <el-input v-model="baseForm.supplierRate" disabled></el-input>
            <i class="iconfont icon-youjiantou"></i>
            <el-input v-model="baseForm.managementRate" class="change_picker" onkeyup="value=value.replace(/[^\-?\d.]/g,'')"  maxlength="3" @change="exceptArrivalChange()" @input="codeInput2">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="financingInviteDialog = false">取 消</el-button>
        <el-button class="footer_button1" type="primary" v-show="footer_button1Not" @click="inviteNow(rulesForm)">立即邀约</el-button>
        <el-button class="bg_button2 footer_button1" type="primary" v-show="!footer_button1Not">立即邀约</el-button>
      </span>
    </el-dialog>
    <!-- 发票详情弹框 -->
    <el-dialog title="发票详情" :visible.sync="invoiceDetailDialog" center class="dialog_width500 dialog_add_edit_form width_1000" :close-on-click-modal="false">
      <el-form :model="invPartner" refs="invPartner">
        <div class="width_1000_left">
          <div class="add_invoice_message inner_common notXin">
            <el-form-item label="采购商名称:" prop="purchaser">
              <el-input v-model="invPartner.purchaseName" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票号码:" prop="invoiceNum">
              <el-input v-model="invPartner.invoiceNo" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票金额:" prop="invoiceMoney">
              <el-input v-model="invPartner.invoiceAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票日期:" prop="invoiceDate">
              <el-input v-model="invPartner.invoiceDate" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票账期:" prop="invoiceTerm">
              <el-input v-model="invPartner.accPeriod" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票确认状态:" prop="accPeriod">
              <el-input v-model="invPartner.isConfirm" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票付款状态:" prop="invoiceTerm">
              <el-input v-model="invPartner.invoiceState" disabled></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="width_1000_right">
          <div class="add_invoice_message inner_common notXin">
            <el-form-item label="付款金额:" prop="paymentAmount">
              <el-input v-model="invPartner.paymentAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票付款日期:" prop="paymentDate">
              <el-input v-model="invPartner.paymentDate" disabled></el-input>
            </el-form-item>
            <el-form-item label="采购商付款凭证:" prop="invImg" class="label_width_special">
              <ul class="invimg_list flex_wrap" v-show="invimgDisplay">
                <li class="flex_center">
                  <img :src="jpg + invPartner.paymentImg">
                </li>
              </ul>
            </el-form-item>
            <el-form-item label="买家是否已收货:" prop="invoiceTerm" class="label_width_special special_disabled">
              <el-input v-model="invPartner.isReceived" disabled></el-input>
            </el-form-item>
            <el-form-item label="买家是否已收发票:" prop="invoiceTerm" class="label_width_special special_disabled">
              <el-input v-model="invPartner.isInvoice" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票图片:" prop="invImg" class="label_width_special">
              <ul class="invimg_list flex_wrap">
                <li class="flex_center">
                  <img :src="jpg + invPartner.invImg">
                </li>
              </ul>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="invoiceDetailDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
    <div v-show="detailDisplay">
      <!-- <div class="second_return_icon" @click="returnButton()" v-show="iconShow">
                <i class="iconfont icon-fanhui" ></i>
                融资企业详情
            </div> -->
      <Company ref="Company"></Company>
    </div>
  </div>
</template>

<script>
import { mixins1 } from "@/mixins/index";
import Page from '@/components/page'
import Company from '../../supplier/components/companyDetail.vue'
import { findPreferenceList, getPubPriSeaList, findPubCount, managementInvitation, findCodeInfoByKey, mInvitation, getInvoiceUrl } from '@/api/investor.js'
import { getInvoiceList } from '@/api/supplier.js'
export default {
  mixins: [mixins1],
  components: {
    Page,
    Company,
  },
  data() {
    const valErrorManager = (rule, val, cb) => {
      if (val.length === 0) {
        return cb(new Error('请输入客户经理'))
      }
      return cb()
    }
    const valiedateTel = (rule, val, cb) => {
      if (val.length === 0) {
        return cb(new Error('请输入电话号码'))
      } else if (!/^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/.test(val))
        return cb(new Error('请输入正确的电话号码格式'))
      return cb()
    }
    const valManagementRate = (rule, val, cb) => {
      if (val == '') {
        return cb()
      }
      else if (val === 0 || val === '0') {
        return cb(new Error('请输入大于0的数字'))
      }
      else if ((val < 0) || (val >= 100)) {
        return cb(new Error('请输入0-100之间数字'))
      }
      return cb()
    }
    const valManagementDay = (rule, val, cb) => {
      if (val == '') {
        return cb()
      }
      else if (val <8 ) {
        return cb(new Error('放款天数需大于7天'))
      }
      return cb()
    }
    const valManagementMoney = (rule, val, cb) => {
      if (val == '') {
        return cb()
      }
      else if (val === 0 || val === '0') {
        return cb(new Error('融资金额不能为0'))
      }
      return cb()
    }
    
    
    return {
      gradeLists: [],
      companyState: [],
      yuoPreference: [],
      list1: [
        {
          value: 0,
          name: '已邀约数'
        },
        {
          value: 0,
          name: '未邀约数'
        },
      ],
      financingForms: {
        financingNum: '',
        financingName: '',
        financingEnterpriseType: '',
        financingEnterpriseGrade: '',
        applyDate: '',
        pageNo: 1,
        pageSize: 8,
        startTime: '',
        endTime: '',
        preference: ''
      },
      id: '',
      tableData: [
      ],
      treeTableData: [
      ],
      baseForm: {
        companyName: '',
        accountManager: '',
        contactInfo: '',
        managementArrivalDate: '',
        supplierArrivalDate: '',
        managementRepaymentDate: '',
        supplierRepaymentDate: '',
        managementFinancingAmount: '',
        supplierFinancingAmount: '',
        managementRate: '',
        supplierRate: '',
        ms: ''
      },
      pickerOptions2: {
        disabledDate: (time) => {
          if (this.baseForm.managementArrivalDate != '') {
            return (
              time.getTime() < Date.now() + (this.baseForm.managementArrivalDate) * 24 * 3600 * 1000 + 30 * 24 * 3600 * 1000
            )
          } else {
            return time.getTime() < Date.now() + 30 * 24 * 3600 * 1000
          }
        }
      },
      invPartner: {
        purchaseName: '',
        invoiceNo: '',
        invoiceAmount: '',
        invoiceDate: '',
        accPeriod: '',
        isConfirm: '',
        invoiceState: '',
        paymentAmount: '',
        paymentDate: '',
        paymentImg: [{ img: '' }],
        isReceived: '',
        isInvoice: '',
        invImg: [{ img: '' }]
      },
      rulesForm: {
        accountManager: [
          { required: true, validator: valErrorManager, trigger: 'blur' }
        ],
        contactInfo: [
          { required: true, validator: valiedateTel, trigger: 'blur' }
        ],
      },
      rulesForm1: {
        managementArrivalDate: [
          { validator: valManagementDay, trigger: 'blur' }
        ],
        managementFinancingAmount: [
          { validator: valManagementMoney, trigger: 'blur' }
        ],
        managementRate: [
          { validator: valManagementRate, trigger: 'blur' }
        ],
        
      },
      financingInviteDialog: false,
      detailDisplay: false,
      publicResource: true,
      detailDisplay1: false,
      detailDisplay2: false,
      detailDisplay3: false,
      invoiceDetailDialog: false,
      classChange: false,
      supplierNum: 0,
      alreadyInviteNum: 0,
      exceptArrivalClass: false,
      exceptRepaymentClass: false,
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      page1: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      userid: '',
      cnCompany: '',
      onClickExpansionFinancingNum: '',
      onClickExpansionIndex: '',
      onClickExpansionIndexkey: 1,
      invoiceNUms: '',
      fapiaomoney: 0,
      footer_button1Not: true,
      invimgDisplay: true,
      preferenceSelect: true,
      fapiaoAccount: [],
      iconShow: false,
      invoiceAImg: require('@/assets/img/system/invoice_A.png'),
      invoiceBImg: require('@/assets/img/system/invoice_B.png'),
      invoiceCImg: require('@/assets/img/system/invoice_C.png'),
      invoiceDImg: require('@/assets/img/system/invoice_D.png'),
      invoiceNaImg: require('@/assets/img/system/invoice_NA.png'),
      invoiceGradeDialog: false,
      invoiceImg: ''
    }
  },
  created() {
    this.resizeFn()
    this.totalComputed()
    this.tableWidth()
    this.getDate()
    this.ficationList()
    this.ficationList1()
    this.findPreference()
  },
  methods: {
    // 发票评级提示
    clickInvoiceGrade(val) {
      this.invoiceGradeDialog = true
      if (val == 'A') {
        this.invoiceImg = 1
      } else if (val == 'B') {
        this.invoiceImg = 2
      } else if (val == 'C') {
        this.invoiceImg = 3
      } else if (val == 'D') {
        this.invoiceImg = 4
      } else {
        this.invoiceImg = 5
      }
    },
    searchInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForms.financingNum = val
    },
    codeInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.baseForm.managementArrivalDate = val
    },
    codeInput1(val) {
      val = val.replace(/[^\d]/g, '')
      this.baseForm.managementFinancingAmount = val
    },
    codeInput2(val) {
      val = val.replace(/[^\d]/g, '')
      this.baseForm.managementRate = val
    },
    async getDate() {
      let datas = localStorage.getItem('userInfo')
      datas = JSON.parse(datas)
      this.userid = datas.userId
      datas = localStorage.getItem('cnCompany')
      this.cnCompany = datas
      if (this.financingForms.applyDate != null) {
        if (this.financingForms.applyDate[0]) {
          this.financingForms.startTime = this.financingForms.applyDate.slice(',')[0]
          this.financingForms.endTime = this.financingForms.applyDate.slice(',')[1]
        } else {
          this.financingForms.startTime = ''
          this.financingForms.endTime = ''
        }
      } else {
        this.financingForms.startTime = ''
        this.financingForms.endTime = ''
      }
      let res = await getPubPriSeaList('pageNo=' + this.page.pageNo + '&pageSize=' + this.page.pageSize
        + '&financingNum=' + this.financingForms.financingNum + '&financingName=' + this.financingForms.financingName +
        '&financingEnterpriseType=' + this.financingForms.financingEnterpriseType +
        '&financingEnterpriseGrade=' + this.financingForms.financingEnterpriseGrade + '&ms=' + 1
        + '&startTime=' + this.financingForms.startTime + '&endTime=' + this.financingForms.endTime + '&preference=' + this.financingForms.preference)
      this.tableData = res.data.records
      this.page.total = res.data.total
      this.baseForm.companyName = this.cnCompany
      res = await findPubCount()
      this.list1[0].value = res.data.invited_quantity
      this.list1[1].value = res.data.unsolicited_quantity
      this.supplierNum = res.data.total_financing_count
      this.alreadyInviteNum = res.data.invited_quantity
      this.$nextTick(function () {
        this.riskEchart2(
          this.fontSize,
          this.echartsWidth
        )
      })
    },
    // 检索按钮
    search_buttons() {
      this.page.pageNo = 1
      this.page1.pageNo = 1
      this.getDate()
      if (this.financingForms.preference) {
        this.baseForm.ms = '1'
      } else {
        this.baseForm.ms = ''
      }
    },
    async getChildernList(item) {
      let res = await getInvoiceList('pageNo=' + this.page1.pageNo + '&pageSize=' + this.page1.pageSize
        + '&financingNum=' + item)
      this.treeTableData = res.data.records
      this.page1.total = res.data.total
    },
    async onPageChangeList(item) {
      this.page1.pageNo = item
      let res = await getInvoiceList('pageNo=' + this.page1.pageNo + '&pageSize=' + this.page1.pageSize
        + '&financingNum=' + this.onClickExpansionFinancingNum)
      this.treeTableData = res.data.records
    },
    async inviteList() {
      this.baseForm.supplierFinancingAmount = this.filterOut(this.baseForm.supplierFinancingAmount)
      this.baseForm.managementFinancingAmount = this.filterOut(this.baseForm.managementFinancingAmount)
      if (this.baseForm.managementArrivalDate == '') {
        this.baseForm.managementArrivalDate = this.baseForm.supplierArrivalDate
      }
      if (this.baseForm.managementRepaymentDate == '') {
        this.baseForm.managementRepaymentDate = this.baseForm.supplierRepaymentDate
      }
      if (this.baseForm.managementFinancingAmount == '') {
        this.baseForm.managementFinancingAmount = this.baseForm.supplierFinancingAmount
      }
      if (this.baseForm.managementRate == '') {
        this.baseForm.managementRate = this.baseForm.supplierRate
      }
      let res = await managementInvitation(this.baseForm)
    },
    async ficationList() {
      let res = await findCodeInfoByKey('ms=' + 28)
      console.log(res)
      this.companyState = res.data
    },
    async ficationList1() {
      let res = await findCodeInfoByKey('ms=' + 9)
      console.log(res)
      this.gradeLists = res.data
    },
    async findPreference() {
      let res = await findPreferenceList()
      res.data.riskPreferenceList.forEach((item, index) => {
        this.yuoPreference.push(item.businessName)
        console.log(this.yuoPreference)
      })
    },
    resetButton() {
      this.$refs.financingForm.resetFields()
      this.page.pageNo = 1
      this.page1.pageNo = 1
      this.getDate()
    },
    // regTime() {
    //   if (this.returnBUttonSpecial == 3) {
    //     if (this.financingForm.exceptArrival || this.financingForm.exceptRate) {
    //       this.backgroundColor = true
    //     } else {
    //       this.backgroundColor = false
    //     }
    //   } else {
    //     if (this.financingForm.exceptArrival || this.financingForm.exceptRepayment || this.financingForm.exceptMoney || this.financingForm.exceptRate) {
    //       this.backgroundColor = true
    //     } else {
    //       this.backgroundColor = false
    //     }
    //   }
    // },
    exceptArrivalChange() {
      if (this.baseForm.managementArrivalDate) {
        this.exceptArrivalClass = true
      } else {
        this.exceptArrivalClass = false
      }
      if (this.baseForm.managementRepaymentDate) {
        this.exceptRepaymentClass = true
      } else {
        this.exceptRepaymentClass = false
      }
      if ((this.baseForm.managementRate > 100) && (this.baseForm.managementRate)) {
        this.footer_button1Not = false
      }
      else {
        this.footer_button1Not = true
      }
    },
    changeDay(){
      this.baseForm.managementRepaymentDate=null
    },
    // echarts图标参数设置
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.fontSize = 14
        this.echartsWidth = 36
        this.financingNumWidth = 176
        this.companyNameWidth = 200
        this.financingWidth = 120
        this.invoiceNum = 85
        this.operationWidth = 140
        this.width145 = 145
        this.width115 = 110
        this.width150s = 150
        this.width100 = 85
      } else {
        this.fontSize = 10
        this.echartsWidth = 24
        this.financingNumWidth = 117
        this.companyNameWidth = 133
        this.financingWidth = 80
        this.invoiceNum = 56
        // this.operationWidth = 100
        this.width145 = 115
        this.width115 = 95
        this.width150s = 120
        this.width100 = 70
      }
      this.$nextTick(function () {
        this.riskEchart2(
          this.fontSize,
          this.echartsWidth
        )
      })
    },
    // 展开子表格
    onClickExpansion(row) {
      this.onClickExpansionFinancingNum = row.financingNum
      this.tableData.map((item) => {
        if (row.id != item.id) {
          item.isExpand = false;
          this.$refs.treeTable.toggleRowExpansion(item, false);
        }
      })
      row.isExpand = !row.isExpand;
      this.$refs.treeTable.toggleRowExpansion(row)
      if (row.isExpand) {
        this.getChildernList(row.financingNum)
      }
    },
    // 融资邀约
    async financingInvite(row) {
      let res = await mInvitation('financingNum=' + row.financingNum)
      if (res && res.code == 200) {
        this.financingInviteDialog = true
        this.baseForm.supplierFinancingAmount = this.filter(row.supplierFinancingAmount)
        this.baseForm.id = row.userCode
        this.baseForm.financingNum = row.financingNum
        this.baseForm.supplierArrivalDate = row.supplyRepaymentDay
        this.baseForm.supplierRepaymentDate = row.supplierRepaymentDate
        this.baseForm.supplierRate = row.supplierRate
        this.$nextTick(() => {
          this.$refs.baseForm1.resetFields()
          this.$refs.baseForm2.resetFields()
        })
        res.data.forEach((item, index) => {
          this.fapiaoAccount.push(item.invoiceAmount)
        })
        this.fapiaoAccount.forEach((item, index) => {
          console.log(item, index)
          this.fapiaomoney = this.fapiaomoney + this.fapiaoAccount[index]
        })
      }
      else if (res.code == 100) {
        this.$message({
          message: '该融资编号已被您邀约!',
          type: 'warning'
        });
      }
    },
    // 立即邀约
    inviteNow() {
      this.$refs.baseForm1.validate((valid) => {
        if (valid) {
          // if(this.baseForm.managementArrivalDate || this.baseForm.managementRepaymentDate || this.baseForm.managementFinancingAmount || this.baseForm.managementRate){
          this.$refs.baseForm2.validate((valid) => {
            if (valid) {
              if (
                (this.baseForm.managementArrivalDate && !this.baseForm.managementRepaymentDate)
                || (!this.baseForm.managementArrivalDate && this.baseForm.managementRepaymentDate)) {
                this.footer_button1Not = false
                this.$message({
                  message: "放款天数和还款日期需同时填写!",
                  type: 'warning'
                })
              } else if ((this.baseForm.managementArrivalDate < 7) && (this.baseForm.managementArrivalDate)) {
                this.footer_button1Not = false
                this.$message({
                  message: '放款天数需大于7天!',
                  type: 'warning'
                });
              } else if (this.baseForm.managementFinancingAmount && (parseInt(this.filterOut(this.baseForm.managementFinancingAmount)) > parseInt(this.fapiaomoney))) {
                this.footer_button1Not = false
                this.$message({
                  message: '您的融资金额不能高于发票总金额!',
                  type: 'warning'
                });
              } else {
                this.inviteList()
                this.financingInviteDialog = false
                this.getDate()
              }
            }
            else {
              return false;
            }
          })
          // }
          // else{
          //     return false
          // }
        }
        else {
          return false;
        }
      })
    },
    // 融资详情
    detailDialog(index) {
      if (index === 0) {
        this.detailDisplay = true
        this.publicResource = false
        this.$nextTick(function () {
          window.scrollTo(0, 0)
        })
      }
    },
    // 发票详情
    async detailsInvoice(row) {
      // let res = await getInvoiceUrl('invoiceNo='+row.invoiceNo)
      this.invoiceDetailDialog = true
      // this.invPartner.invImg[0].img = res.data
      // let list=this.invPartner.invImg
      this.invPartner = row
      this.invPartner.paymentImg = row.paymentVoucher
      this.invPartner.invImg = row.invoiceUrl
      // this.invPartner.invImg=list
      this.invPartner.invoiceAmount = this.filter(this.invPartner.invoiceAmount)
      this.invPartner.paymentAmount = this.filter(this.invPartner.paymentAmount)
      console.log(row.invoiceState)
      if (row.invoiceState == '0') {
        this.invPartner.invoiceState = '未付款'
        this.invimgDisplay = false
      } else if (row.invoiceState == '1') {
        this.invPartner.invoiceState = '已付款'
        this.invimgDisplay = true
      }
      console.log(row.invoiceState)
      if (row.isConfirm == '0') {
        this.invPartner.isConfirm = '未认可'
      } else if (row.isConfirm == '1') {
        this.invPartner.isConfirm = '认可'
      }
      if (row.isReceived == '0') {
        this.invPartner.isReceived = '否'
      } else if (row.isReceived == '1') {
        this.invPartner.isReceived = '是'
      }
      if (row.isInvoice == '0') {
        this.invPartner.isInvoice = '否'
      } else if (row.isInvoice == '1') {
        this.invPartner.isInvoice = '是'
      }
    },
    // 返回前一页
    returnButton() {
      this.detailDisplay = false
      this.publicResource = true
      this.detailDisplay1 = false
      this.detailDisplay2 = false
      this.detailDisplay3 = false
      window.scrollTo(0, 0)
    },
    // 供应商和核心企业总数计算
    totalComputed() {
      this.supplierNum = this.list1[0].value + this.list1[1].value
      this.alreadyInviteNum = this.list1[0].value
    },
    detailIndex(row) {
      this.$refs.Company.getCompany(row.financingName)
      this.publicResource = false
      this.detailDisplay = true
      // this.getData1()
    },
    // 采购商名称
    detailIndex1(row) {
      this.$refs.Company.getCompany(row.purchaseName)
      this.publicResource = false
      this.detailDisplay = true
      // this.getData1()
    },
    inputMoneyFocusfin(value) {
      this.baseForm.managementFinancingAmount = this.filterOut(value)
    },
    inputMoneyBlurfin(value) {
      this.baseForm.managementFinancingAmount = this.filter(value )
    },
  }
}
</script>

<style scoped>
  ::v-deep .el-table th > .cell {
    z-index: 1;
  }
  .bg_button2 {
    background: #dae4e9;
    border: 1px solid #dae4e9;
    cursor: not-allowed;
  }
  ::v-deep .exceptArrivalClass .el-date-editor--date:hover .el-input__prefix {
    display: none;
  }
  ::v-deep .exceptRepaymentClass .el-date-editor--date:hover .el-input__prefix {
    display: none;
  }
  .apply_box_top_left {
    width: 340px;
    height: 176px;
    margin-right: 60px;
    padding: 20px;
    background: #339c9b;
    box-shadow: 0px 10px 20px 0px rgba(47, 160, 159, 0.23);
    border-radius: 14px;
  }

  .table_box_top {
    float: right;
  }
  .class_change1 {
    background-image: url(../../../../assets/img/system/investor/echarts_tab.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
  }
  .class_change2 {
    background-image: url(../../../../assets/img/system/investor/echarts_tab1.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
  }
  .apply_box_total {
    display: flex;
    align-items: center;
    width: 300px;
    height: 136px;
    padding-top: 20px;
    padding-bottom: 24px;
    padding-left: 16px;
    background: rgba(237, 242, 245, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
    border-radius: 14px;
  }
  .apply_bottom {
    margin-bottom: 10px;
  }
  .echarts_box {
    height: 250px;
    margin-top: -40px;
  }
  .search_flex_box >>> .el-form-item {
    margin-bottom: 20px;
  }
  .search_flex_box >>> .el-form-item:last-child {
    margin-bottom: 0;
  }
  .search_flex_box >>> .el-form-item:nth-child(3) {
    margin-right: 0;
  }
  .search_condition {
    flex: 1;
  }
  .search_condition_right >>> .el-form-item {
    margin-right: 28px;
  }
  /* 弹框 */
  .public_box {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 28px;
    padding-right: 20px;
    background: #f7fafc;
    border-radius: 10px;
  }
  .base_message_box {
    height: 244px;
    margin-bottom: 10px;
  }
  .base_message_box >>> .el-form-item {
    margin-bottom: 20px;
  }
  .base_message_box >>> .el-form-item:last-child {
    margin-bottom: 0;
  }
  .lending_conditions_box {
    height: 304px;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  .lending_conditions_box .public_title {
    margin-left: 0;
  }
  .lending_conditions_box >>> .el-form-item {
    margin-bottom: 20px;
  }
  .lending_conditions_box >>> .el-form-item :last-child {
    margin-bottom: 0;
  }
  .public_title {
    margin-bottom: 20px;
    margin-left: -8px;
    font-size: 14px;
    color: #162747;
  }
  .change_padding >>> .el-form-item__label {
    min-width: 70px;
  }
  .lending_conditions_box >>> .el-form-item__label {
    width: 60px;
  }
  .lending_conditions_box >>> .el-input {
    width: 165px;
  }
  .lending_conditions_box i {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 14px;
    color: #7c969b;
  }
  .lending_conditions_box >>> .el-icon-date {
    position: relative;
    left: 135px;
  }
  .invimg_list li {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 12px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .invimg_list img:hover {
    position: absolute;
    bottom: -1px;
    left: 0px;
    cursor: pointer;
    transform: scale(1);
  }
  .special_disabled >>> .el-input.is-disabled .el-input__inner {
    background: #ffffff;
    border: 1px solid #ffffff;
    color: #162747;
  }
  .special_disabled >>> .el-input__inner {
    padding: 0;
  }
  .lending_conditions_box >>> .el-form-item__error {
    left: 55% !important;
  }
  .financing_number >>> .el-form-item__label {
    min-width: 98px !important;
  }
  @media screen and (max-width: 1664px) {
    .select_class >>> .el-input__inner {
      padding-left: 10px;
    }
    .apply_box_top_left {
      width: 226px;
      height: 117px;
      margin-right: 40px;
      padding: 13px;
      background: #339c9b;
      box-shadow: 0px 6px 13px 0px rgba(47, 160, 159, 0.23);
      border-radius: 10px;
    }
    .apply_box_total {
      display: flex;
      align-items: center;
      width: 200px;
      height: 90px;
      padding-top: 13px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 13px;
      background: rgba(237, 242, 245, 0.2);
      box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
      border-radius: 10px;
    }
    .apply_bottom {
      margin-bottom: 6px;
    }
    .echarts_box {
      height: 166px;
      margin-top: -26px;
    }
    .search_flex_box >>> .el-form-item {
      margin-bottom: 13px;
    }
    .search_condition_right >>> .el-form-item {
      margin-right: 4px;
    }
    /* 弹框 */
    .public_box {
      width: 100%;
      padding-top: 6px;
      padding-bottom: 20px;
      padding-left: 18px;
      padding-right: 13px;
      background: #f7fafc;
      border-radius: 6px;
    }
    .base_message_box {
      height: 160px;
      margin-bottom: 6px;
    }
    .base_message_box >>> .el-form-item:last-child {
      margin-bottom: 0;
    }
    .base_message_box >>> .el-form-item {
      margin-bottom: 13px;
    }

    .lending_conditions_box >>> .el-form-item {
      margin-bottom: 13px;
    }
    .lending_conditions_box >>> .el-form-item :last-child {
      margin-bottom: 0;
    }
    .lending_conditions_box {
      height: 200px;
      margin-bottom: 13px;
      padding-left: 13px;
    }
    .public_title {
      margin-bottom: 13px;
      margin-left: -5px;
      font-size: 12px;
      color: #162747;
    }
    .change_padding >>> .el-form-item__label {
      min-width: 60px;
    }
    .lending_conditions_box >>> .el-form-item__label {
      width: 40px;
    }
    .lending_conditions_box >>> .el-input {
      width: 102px;
    }
    .lending_conditions_box i {
      margin-left: 9px;
      margin-right: 9px;
      font-size: 9px;
      color: #7c969b;
    }
    .lending_conditions_box >>> .el-icon-date {
      position: relative;
      left: 72px;
    }
    .invimg_list li {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .invimg_list img:hover {
      position: absolute;
      bottom: -1px;
      left: 0px;
      cursor: pointer;
      transform: scale(1);
    }
    .lending_conditions_box >>> .el-form-item__error {
      left: 55% !important;
    }
    .financing_number >>> .el-form-item__label {
      min-width: 83px !important;
    }
    .change_picker >>> .el-input__inner {
      padding-left: 3px !important;
    }
  }
</style>